<div class='main-content'>
  <app-header
    title="{{ 'Announcements' | translate }}"
    [hasCustomAddButton]="false">
  </app-header>

  <div class='row no-gutter my-3 d-flex justify-content-end px-3'>
    <button class="btn btn-primary d-flex align-items-center" (click)='openModal(editOrCreate)'>
      <img src="assets/svgs/plus_icon.svg" width='20' height='20' class="mr-1">
      {{ 'Add Announcement' | translate}}
    </button>
  </div>

  
  <!--------------------------------Announcement Table ------------------------>
  <div class='px-3' *ngIf="!loading">
    <table
      class="table table-hover text-center table-borderless table-striped mb-5"
      style="border-radius: 5px; box-shadow: 3px 3px 3px #cececebf;"
      *ngIf="!loading && announcements.length">
      <thead>
        <tr>
          <th width="15%">{{ 'Picture' | translate }}</th>
          <th width="18%">{{ 'Dealer' | translate }}</th>
          <th width="18%">{{ 'Shop' | translate }}</th>
          <th width="15%">{{ 'Message' | translate }}</th>
          <th width="12%">{{ 'Start Date' | translate }}</th>
          <th width="12%">{{ 'End Date' | translate }}</th>
          <th width="5%">{{ 'edit' | translate }}</th>
          <th width="5%">{{ 'Delete' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let announcement of announcements; let i = index;">
          <td class="align-middle">
            <div class='filler-image' *ngIf="!announcement.picture">
              <img src='assets/svgs/no_image.svg' width='40' height='40' >
            </div>
            <img [src]='announcement.picture' width='auto' height='80' *ngIf="announcement.picture">
          </td>
          <td class="align-middle">
            {{ announcement.all_dealer ? ('All' | translate) : (announcement.dealer_full_name || '-') }}
          </td>
          <td class="align-middle">
            {{ announcement.all_shop ? ('All' | translate) : (announcement.shop_name || '-') }}
          </td>
          <td class="align-middle">{{ announcement.message || '-' }}</td>
          <td class="align-middle">{{ announcement.start_date || '-' }}</td>
          <td class="align-middle">{{ announcement.end_date || '-' }}</td>
          <td class="align-middle">
            <img src='assets/svgs/edit_pen.svg' width='25' height='25'
              (click)='openModal(editOrCreate, announcement)'>
          </td>
          <td class="align-middle">
            <img src='assets/svgs/red_trash.svg' width='25' height='25'
              (click)='openConfirm(announcement.id)'>
          </td>
        </tr>
      </tbody>
    </table>
    <app-pagination
      *ngIf="!loading && this.announcements.length"
      [current]="currentPage"
      [pages]="noOfPage"
      (navigate)="navigateTo($event)">
    </app-pagination>
  </div>
  <div class='row no-gutter d-flex justify-content-center'
       *ngIf="!loading && !this.announcements.length">
    <h1>{{ 'No New Announcement' | translate }}</h1>
  </div>
  <app-loading class="w-100 my-3" *ngIf="loading"></app-loading>
</div>

<ng-template #editOrCreate>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class='m-0'>{{ (type === 'add' ? 'Add Announcement' : 'Edit Announcement') | translate}}</h4>
    </div>
    <div class='modal-body'>

      <div class='row no-gutter mb-4'>
        <ngx-file-drop
            class="w-100"
            dropZoneLabel="Drop files here"
           (onFileDrop)="dropped($event)"
           [multiple]="false"
           accept=".png,.jpg">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div class="w-100 h-100 clickable"
                 (click)="openFileSelector()" *ngIf="!imageUrl">
              <div class="row my-4 d-flex justify-content-center">
                <img src="assets/svgs/file_upload.svg" height="60px" width="auto">
              </div>
              <h4 class="row d-flex justify-content-center text-secondary">
                {{'browseOrDrop' | translate}}
              </h4>
            </div>

            <div class="w-100 h-100 row d-flex justify-content-center align-items-center clickable"
                 *ngIf="imageUrl">
              <div class="col-12 d-flex justify-content-center mt-1" (click)="openFileSelector()">
                <img [src]="imageUrl" alt="" class="preview-img">
              </div>
              <div class="rmv-img" (click)="removeImage()" *ngIf="type=='add'">
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          </ng-template>
        </ngx-file-drop>
      </div>

      <div class='row no-gutter my-3'>
        <label class="col-4 pt-2">{{ 'Message' | translate }}</label>
        <textarea cols='30' rows='4' class='col-8 form-control'
          [(ngModel)]="announcement['message']"
          [placeholder]="'Write Message Here' | translate"
        >
        </textarea>
      </div>

      <div class='row no-gutter my-3'>
        <label class="col-4 m-0 align-self-center">
          {{ 'Start Date' | translate }}
        </label>
        <input
          type='text'
          class="col-8 form-control"
          (click)="d.toggle()"
          name="dp"
          [placeholder]="'Select Start Date' | translate"
          ngbDatepicker
          [(ngModel)]="announcement['startDate']"
          [readOnly]='true'
          #d="ngbDatepicker">
      </div>

      <div class='row no-gutter my-3'>
        <label class="col-4 m-0 align-self-center">
          {{ 'End Date' | translate }}
        </label>
        <input
          class="col-8 form-control"
          type='text'
          (click)="g.toggle()"
          #g="ngbDatepicker"
          name="dp"
          [placeholder]="'Select End Date' | translate"
          [minDate]="announcement['startDate']"
          [disabled]="!announcement['startDate']"
          [(ngModel)]="announcement['endDate']"
          [readonly]="announcement['startDate']"
          ngbDatepicker>
      </div>

      <div class='row no-gutter my-3'>
        <label class="col-4 m-0 align-self-center">
          {{ 'Dealer' | translate }}
        </label>
        <ng-select
          class='col-8 p-0'
          *ngIf="getLang() === 'en'"
          [clearable]="false"
          [loading]="loadingDealers"
          [items]="dealers"
          [placeholder]=" 'dealer.selectDealer' | translate "
          [multiple]="type === 'add'"
          bindLabel='full_name'
          bindValue="id"
          (change)='getShops(true)'
          [(ngModel)]="announcement['dealer_id']"
          [notFoundText]="'notFound' | translate">
        </ng-select>
        <ng-select
          class='col-8 p-0'
          *ngIf="getLang() === 'ja'"
          [clearable]="false"
          [loading]="loadingDealers"
          [items]="dealers"
          [placeholder]=" 'dealer.selectDealer' | translate "
          [multiple]="type === 'add'"
          bindLabel='full_name'
          bindValue="id"
          (change)='getShops(true)'
          [(ngModel)]="announcement['dealer_id']"
          [notFoundText]="'notFound' | translate">
        </ng-select>

      </div>

      <div class='row no-gutter my-3'>
        <label class="col-4 m-0 align-self-center">
          {{ 'Shop' | translate }}
        </label>
        <ng-select
          class='col-8 p-0'
          *ngIf="getLang() === 'en'"
          [clearable]="false"
          [loading]="loadingShops"
          [items]="shops"
          [placeholder]=" 'shop.selectShop' | translate "
          [multiple]="type === 'add'"
          bindLabel='name'
          bindValue="id"
          [disabled]="!announcement['dealer_id'] || announcement['dealer_id'].length > 1 || announcement['dealer_id'][0] == 0 "
          [(ngModel)]="announcement['shop_id']"
          [notFoundText]="'notFound' | translate">
        </ng-select>
        <ng-select
          class='col-8 p-0'
          *ngIf="getLang() === 'ja'"
          [clearable]="false"
          [loading]="loadingShops"
          [items]="shops"
          [placeholder]=" 'shop.selectShop' | translate "
          [multiple]="type === 'add'"
          bindLabel='name_jp'
          bindValue="id"
          [disabled]="!announcement['dealer_id'] || announcement['dealer_id'].length > 1 || announcement['dealer_id'][0] == 0"
          [(ngModel)]="announcement['shop_id']"
          [notFoundText]="'notFound' | translate">
        </ng-select>

      </div>
    </div>

    <div class='modal-footer'>
      <button class="btn btn-secondary mr-2" (click)="dismissEditOrCreate()">
        {{ 'Cancel' | translate }}
      </button>
      <button class='btn btn-primary' (click)="save()" [disabled]='savingAnnouncement'>
        {{ 'Save' | translate }}
      </button>
    </div>
  </div>
</ng-template>
