import {Injectable} from '@angular/core';
import {HttpClientService} from 'src/app/services/http-client.service';
import {API_CONSTANT} from 'src/app/services/api.constants';

@Injectable({
  providedIn: 'root'
})
export class CarSpecificationService {
  constructor(private api: HttpClientService) {
  }

  getCarData(params?) {
    return this.api.get(API_CONSTANT.BASE_URL + '/car-models/', params);
  }

  importCarData(data?) {
    return this.api.post(API_CONSTANT.BASE_URL + '/import-katashiki/', data);
  }

  getCarDataNames() {
    return this.api.get(API_CONSTANT.BASE_URL + '/get-all-car-data-names/');
  }

  delete(id: number) {
    return this.api.delete(API_CONSTANT.BASE_URL + `/car-models/${id}/`);
  }
}
